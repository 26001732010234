import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

// import { validateForm } from './CategoryCreate';



// const title = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;




const OrdersEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
        // validate={validateForm}
        >

            <SectionTitle label="resources.news.category.fieldGroups.main" />
            <NumberInput source="billingAccountId" isRequired fullWidth />
            <NumberInput source="marketdataPairId" isRequired fullWidth />
            <TextInput source="instrument" fullWidth />
            <NumberInput source="price" isRequired fullWidth />
            <NumberInput source="time" isRequired fullWidth />
            <TextInput source="qty" fullWidth />
            <NumberInput source="orderId" isRequired fullWidth />
            <TextInput source="isClose" fullWidth />
            <TextInput source="positionId" fullWidth />
            <TextInput source="commission" fullWidth />



            <Separator />
            <SectionTitle label="resources.news.category.fieldGroups.status" />

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default OrdersEdit;
