import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


// const tariffPlanField = (record: any ) => `#${record.id} #${record.name}`;



const PostsList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />

                <ReferenceField source="newsCategoryId" reference="news/categories" label="resources.news.posts.fields.category">
                    <TextField source="name" />
                </ReferenceField>

                <TextField source="title" label="resources.news.posts.fields.title" />
                <TextField source="preview" label="resources.news.posts.fields.preview" />
                <TextField source="content" label="resources.news.posts.fields.content" />

                <DateField source="beginAt" showTime label="resources.news.posts.fields.beginAt" />
                <DateField source="endAt" showTime label="resources.news.posts.fields.endAt" />

                <BooleanField source="active" label="resources.news.posts.fields.active" />
                <DateField source="createdAt" showTime label="resources.news.posts.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.news.posts.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default PostsList;
