import StageIcon from '@mui/icons-material/People';

import StageList from './StageList';
import StageCreate from './StageCreate';
import StageEdit from './StageEdit';

const resource = {
    list: StageList,
    create: StageCreate,
    edit: StageEdit,
    icon: StageIcon,
};

export default resource;
