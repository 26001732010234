import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './CategoryCreate';



// const title = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;




const CategoryEdit = () => (

    <Edit>
        <SimpleForm 
            sx={{ maxWidth: 500 }}
            validate={validateForm}
        >
        
        <SectionTitle label="resources.news.category.fieldGroups.main" />

        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="name" isRequired fullWidth />
        </Box>

        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="description" fullWidth />
        </Box>


        <Separator />
        <SectionTitle label="resources.news.category.fieldGroups.status" />

        <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="active" fullWidth />
            </Box>
        </Box>

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default CategoryEdit;
