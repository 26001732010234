import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    // if (!values.name) {
    //     errors.name = 'ra.validation.required';
    // }
    return errors;
};


const tariffPlanField = (record: any) => `#id${record.id} #${record.tariff_category.name} #${record.name}`;



const AccountCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.main" />

            <ReferenceInput source="profileUserId" reference="profile/users">
                <SelectInput optionText="username" isRequired fullWidth />
            </ReferenceInput>

            <ReferenceInput source="tariffPlanId" reference="tariff/plans">
                <SelectInput optionText={tariffPlanField} isRequired fullWidth />
            </ReferenceInput>

            <TextInput source="realCostUsdt" fullWidth />
            <TextInput source="startBalance" fullWidth />
            <TextInput source="leverage" fullWidth />

            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.status" />

            <BooleanInput source="active" fullWidth />

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default AccountCreate;
