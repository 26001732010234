import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;


const PositionsList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />

                <ReferenceField source="billingAccountId" reference="billing/accounts" label="resources.billing.wallet.fields.account">
                    <TextField source="id" />
                </ReferenceField>

                <ReferenceField source="marketdataPairId" reference="marketdata/pairs" label="resources.trading.orders.fields.marketdataPairId">
                    <TextField source="id" />
                </ReferenceField>

                <TextField source='instrument' label="resources.trading.orders.fields.instrument" />
                <TextField source='qty' label="resources.trading.orders.fields.qty" />
                <TextField source='side' label="resources.trading.orders.fields.side" />
                <NumberField source='avgPrice' label="resources.trading.orders.fields.avgPrice" />
                <NumberField source='unrealizedPl' label="resources.trading.positions.fields.unrealizedPl" />
                <TextField source='commission' label="resources.trading.orders.fields.commission" />
                <TextField source='messageType' label="resources.trading.orders.fields.messageType" />
                <TextField source='messageText' label="resources.trading.orders.fields.messageText" />

                <DateField source="createdAt" showTime label="resources.billing.wallet.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.wallet.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default PositionsList;
