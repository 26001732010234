import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const tariffPlanField = (record: any) => `#id${record.id} #${record.name}`;



const AccountList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />
                <ReferenceField source="profileUserId" reference="profile/users" label="resources.billing.account.fields.user">
                    <TextField source="username" />
                </ReferenceField>

                <ReferenceField source="tariffPlanId" reference="tariff/plans" label="resources.billing.account.fields.tariff">
                    <FunctionField render={tariffPlanField} />
                </ReferenceField>
                <NumberField source='realCostUsdt' label="resources.billing.account.fields.realCostUsdt" />
                <NumberField source='startBalance' label="resources.billing.account.fields.startBalance" />
                <NumberField source='leverage' label="resources.billing.account.fields.leverage" />

                <BooleanField source="active" label="resources.billing.account.fields.active" />
                <DateField source="createdAt" showTime label="resources.billing.account.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.account.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default AccountList;
