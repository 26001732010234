import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

// import { validateForm } from './CategoryCreate';



// const title = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;




const OrdersEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
        // validate={validateForm}
        >

            <SectionTitle label="resources.news.category.fieldGroups.main" />

            <NumberInput source="billingAccountId" isRequired fullWidth />
            <NumberInput source="marketdataPairId" isRequired fullWidth />
            <TextInput source="instrument" fullWidth />
            <TextInput source="qty" fullWidth />
            <TextInput source="side" fullWidth />
            <TextInput source="type" fullWidth />
            <TextInput source="status" fullWidth />
            <NumberInput source="filledQty" isRequired fullWidth />
            <NumberInput source="avgPrice" isRequired fullWidth />
            <NumberInput source="limitPrice" isRequired fullWidth />
            <NumberInput source="stopPrice" isRequired fullWidth />
            <NumberInput source="trailingStopPips" isRequired fullWidth />
            <NumberInput source="isTrailingStop" isRequired fullWidth />
            <NumberInput source="isGuaranteedStop" isRequired fullWidth />
            <NumberInput source="parentId" isRequired fullWidth />
            <TextInput source="parentType" fullWidth />
            <TextInput source="duration" fullWidth />
            <TextInput source="lastModified" fullWidth />
            <TextInput source="commission" fullWidth />
            <TextInput source="messageType" fullWidth />
            <TextInput source="messageText" fullWidth />

            <Separator />

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default OrdersEdit;
