import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    RichTextField,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.question) {
        errors.question = 'ra.validation.required';
    }
    if (!values.faqCategoryId) {
        errors.faqCategoryId = 'ra.validation.required';
    }
    return errors;
};


// const tariffPlanField = (record: any ) => `#${record.id} #${record.name}`;


const EntryCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 1200 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >

            <SectionTitle label="resources.faq.entry.fieldGroups.main" />

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="faqCategoryId" reference="faq/categories">
                    <SelectInput optionText="name" isRequired />
                </ReferenceInput>
            </Box>

            <TextInput source="question" isRequired fullWidth multiline={true} style={{ height: 100 }} />

            <RichTextInput source="answer" fullWidth style={{ height: 200 }} />


            <Separator />
            <SectionTitle label="resources.faq.entry.fieldGroups.status" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="active" fullWidth />
                </Box>
            </Box>

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default EntryCreate;
