import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './PlanCreate';




// const PlanTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const categoryField = (record: any) => `#${record.id} #${record.name}`;



const PlanEdit = () => (
    <Edit
    // title={<TariffTitle />} 
    >
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}
        >
            <SectionTitle label="resources.tariff.plans.fieldGroups.main" />

            <TextInput source="name" isRequired fullWidth />
            <TextInput source="description" fullWidth />

            <NumberInput source="startBalance" isRequired fullWidth />
            <NumberInput source="leverage" isRequired fullWidth />
            <NumberInput source="costProcent" isRequired fullWidth />
            <NumberInput source="fixedCostUsdt" fullWidth />

            <Separator />
            <SectionTitle label="resources.tariff.plans.fieldGroups.status" />
            <BooleanInput source="active" fullWidth />

        </SimpleForm>

    </Edit>

);



const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default PlanEdit;
