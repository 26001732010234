import * as React from 'react';

import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRedirect,
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { redirect, useLocation } from 'react-router';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    return errors;
};


const categoryField = (record: any) => `#${record.id} #${record.name}`;



const PlanCreate = () => {

    const notify = useNotify();
    const redirect = useRedirect();
    const location = useLocation();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/tariff/plans');
    };


    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm
                sx={{ maxWidth: 500 }}
                // Here for the GQL provider
                defaultValues={{
                    administrator: false,
                    moderator: true,
                    active: true,
                }}
                validate={validateForm}
            >
                <SectionTitle label="resources.tariff.plan.fieldGroups.main" />

                <TextInput source="name" isRequired fullWidth />
                <TextInput source="description" fullWidth />
                <ReferenceInput source="tariffCategoryId" reference="tariff/category">
                    <SelectInput optionText={categoryField} isRequired />
                </ReferenceInput>

                <NumberInput source="startBalance" isRequired fullWidth />
                <NumberInput source="leverage" isRequired fullWidth />
                <NumberInput source="costProcent" isRequired fullWidth />
                <NumberInput source="fixedCostUsdt" fullWidth />

                <Separator />
                <SectionTitle label="resources.tariff.plan.fieldGroups.status" />
                <BooleanInput source="active" fullWidth />

            </SimpleForm>
        </Create>
    )

};


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default PlanCreate;

function notify(arg0: string) {
    throw new Error('Function not implemented.');
}

function refresh() {
    throw new Error('Function not implemented.');
}

