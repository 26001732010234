import PaymentIcon from '@mui/icons-material/People';

import PaymentList from './PaymentList';
import PaymentCreate from './PaymentCreate';
import PaymentEdit from './PaymentEdit';

const resource = {
    list: PaymentList,
    create: PaymentCreate,
    edit: PaymentEdit,
    icon: PaymentIcon,
};

export default resource;
