import EntryIcon from '@mui/icons-material/People';

import OrdersList from './OrdersList';
import OrdersCreate from './OrdersCreate';
import OrdersEdit from './OrdersEdit';

const resource = {
    list: OrdersList,
    create: OrdersCreate,
    edit: OrdersEdit,
    icon: EntryIcon,
};

export default resource;
