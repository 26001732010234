import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

//// chapters imports

// profile
import profileRole from '../pages/profile/role';
import profileUser from '../pages/profile/user';

// tariff
import tariffPlan from '../pages/tariff/plan';
import tariffStage from '../pages/tariff/stage';

// marketdata
import marketdataCoin from '../pages/marketdata/coin';
import marketdataRate from '../pages/marketdata/rate';
import marketdataRateLast from '../pages/marketdata/rateLast';

// billing
import billingAccount from '../pages/billing/account';
import billingWallet from '../pages/billing/wallet';
import billingPayment from '../pages/billing/payment';

// faq
import faqCategory from '../pages/faq/category';
import faqEntry from '../pages/faq/entries';

// helppage
import helppageCategory from '../pages/helppage/category';
import helppageEntry from '../pages/helppage/entries';

// mainpage
import mainpageEntry from '../pages/mainpage/entry';

// news
import newsCategory from '../pages/news/category';
import newsPost from '../pages/news/post';


////
import orders from '../pages/trading/orders';
/// 

import contests from '../pages/billing/contest';



import invoices from '../pages/invoices';
import products from '../pages/products';
import category1 from '../pages/category';
import SubMenu from './SubMenu';



type MenuName =
    'menuProfile' |
    'menuTariff' |
    'menuMarketdata' |
    'menuBilling' |
    'menuFaq' |
    'menuHelppage' |
    'menuMainpage' |
    'menuNews' |
    'menuCatalog' |
    'menuContest' |
    'menuTrading';



const Menu = ({ dense = false }: MenuProps) => {

    const [state, setState] = useState({
        menuProfile: true,
        menuTariff: true,
        menuMarketdata: true,
        menuBilling: true,
        menuFaq: true,
        menuHelppage: true,
        menuMainpage: true,
        menuNews: true,

        menuCatalog: true,
        menuTrading: true,
        menuContest: true,
    });

    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />

            <SubMenu
                handleToggle={() => handleToggle('menuProfile')}
                isOpen={state.menuProfile}
                name="pos.menu.profile"
                icon={<profileUser.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/profile/roles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.profile.role.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/profile/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.profile.users.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />
            </SubMenu>


            <SubMenu
                handleToggle={() => handleToggle('menuTariff')}
                isOpen={state.menuBilling}
                name="pos.menu.tariff"
                icon={<tariffPlan.icon />}
                dense={dense}
            >
                {/* <MenuItemLink
                    to="/tariff/category"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.tariff.category.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<tariffCategory.icon />}
                    dense={dense}
                /> */}

                <MenuItemLink
                    to="/tariff/plans"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.tariff.plans.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<tariffPlan.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/tariff/stages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.tariff.stages.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<tariffStage.icon />}
                    dense={dense}
                />

            </SubMenu>


            <SubMenu
                handleToggle={() => handleToggle('menuMarketdata')}
                isOpen={state.menuMarketdata}
                name="pos.menu.marketdata"
                icon={<profileUser.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/marketdata/coins"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.marketdata.coin.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/marketdata/last/quotes"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.marketdata.rateLast.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/marketdata/quotes"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.marketdata.rate.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />
            </SubMenu>


            <SubMenu
                handleToggle={() => handleToggle('menuBilling')}
                isOpen={state.menuBilling}
                name="pos.menu.billing"
                icon={<billingAccount.icon />}
                dense={dense}
            >

                <MenuItemLink
                    to="/billing/accounts"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.billing.account.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<billingAccount.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/billing/wallets"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.billing.wallet.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<billingWallet.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/billing/contests"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.billing.contest.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />
                {/* <MenuItemLink
                    to="/billing/payment"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.billing.payment.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<billingPayment.icon />}
                    dense={dense}
                /> */}
            </SubMenu>


            <SubMenu
                handleToggle={() => handleToggle('menuFaq')}
                isOpen={state.menuFaq}
                name="pos.menu.faq"
                icon={<profileUser.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/faq/categories"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.faq.categories.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/faq/entries"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.faq.entry.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />
            </SubMenu>


            <SubMenu
                handleToggle={() => handleToggle('menuHelppage')}
                isOpen={state.menuHelppage}
                name="pos.menu.helppage"
                icon={<profileUser.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/helppage/categories"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.helppage.category.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/helppage/entries"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.helppage.entry.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />
            </SubMenu>


            <SubMenu
                handleToggle={() => handleToggle('menuMainpage')}
                isOpen={state.menuMainpage}
                name="pos.menu.mainpage"
                icon={<profileUser.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/mainpage/entries"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.mainpage.entry.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />
            </SubMenu>


            <SubMenu
                handleToggle={() => handleToggle('menuNews')}
                isOpen={state.menuNews}
                name="pos.menu.news"
                icon={<profileUser.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/news/categories"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.news.category.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/news/posts"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.news.posts.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<profileUser.icon />}
                    dense={dense}
                />
            </SubMenu>




            <SubMenu
                handleToggle={() => handleToggle('menuTrading')}
                isOpen={state.menuTrading}
                name="pos.menu.trading"
                icon={<orders.icon />}
                dense={dense}
            >
                {/* <MenuItemLink
                    to="/trading/executions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.trading.executions.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    dense={dense}
                /> */}
                <MenuItemLink
                    to="/trading/orders"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.trading.orders.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    dense={dense}
                />
                {/* <MenuItemLink
                    to="/trading/positions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.trading.positions.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    dense={dense}
                /> */}

            </SubMenu>



            {/*  <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name="pos.menu.catalog"
                icon={<products.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/products"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.products.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<products.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/category1"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.category.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<category1.icon />}
                    dense={dense}
                />
            </SubMenu> */}

        </Box>
    );
};

export default Menu;
