import PlanIcon from '@mui/icons-material/People';

import PlanList from './PlanList';
import PlanCreate from './PlanCreate';
import PlanEdit from './PlanEdit';

const resource = {
    list: PlanList,
    create: PlanCreate,
    edit: PlanEdit,
    icon: PlanIcon,
};

export default resource;
