import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    DateTimeInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    // if (!values.newsCategoryId) {
    //     errors.name = 'ra.validation.required';
    // }
    return errors;
};


// const tariffPlanField = (record: any ) => `#${record.id} #${record.name}`;


const PostsCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >
            <SectionTitle label="resources.news.posts.fieldGroups.main" />

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="newsCategoryId" reference="news/categories">
                    <SelectInput optionText="name" isRequired />
                </ReferenceInput>
            </Box>

            <RichTextInput source="title" isRequired fullWidth />
            <RichTextInput source="preview" fullWidth />
            <RichTextInput source="content" isRequired fullWidth />


            <Separator />
            <SectionTitle label="resources.news.posts.fieldGroups.status" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput source='beginAt' />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput source='endAtAt' />
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="active" fullWidth />
                </Box>
            </Box>

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default PostsCreate;
