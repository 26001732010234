import RateIcon from '@mui/icons-material/People';

import RateList from './RateList';
import RateCreate from './RateCreate';
import RateEdit from './RateEdit';

const resource = {
    list: RateList,
    create: RateCreate,
    edit: RateEdit,
    icon: RateIcon,
};

export default resource;
