import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const tariffFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const tariffPlanField = (record: any) => `#id${record.id} #${record.tariff_category.name} #${record.name}`;


const StageList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={tariffFilters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />
                <TextField source="name" label="resources.tariff.stages.fields.name" />
                <ReferenceField source="tariffPlanId" reference="tariff/plans" label="resources.tariff.stages.fields.plan">
                    <FunctionField render={tariffPlanField} />
                </ReferenceField>

                <NumberField source="minProfitPerStageProcent" label="resources.tariff.stages.fields.minProfitPerStageProcent" />
                <NumberField source="maxLossPerStageProcent" label="resources.tariff.stages.fields.maxLossPerStageProcent" />
                <NumberField source="tradingDaysCount" label="resources.tariff.stages.fields.tradingDaysCount" />
                <NumberField source="minProfitPerDayProcent" label="resources.tariff.stages.fields.minProfitPerDayProcent" />
                <NumberField source="maxLossPerDayProcent" label="resources.tariff.stages.fields.maxLossPerDayProcent" />

                <NumberField source="ordering" label="resources.tariff.stages.fields.ordering" />
                <BooleanField source="active" label="resources.tariff.stages.fields.active" />
                {/* <DateField source="createdAt" showTime label="resources.tariff.stage.fields.createdAt"/> */}
                <DateField source="updatedAt" showTime label="resources.tariff.stages.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default StageList;
