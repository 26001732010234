import WalletIcon from '@mui/icons-material/People';

import WalletList from './WalletList';
import WalletCreate from './WalletCreate';
import WalletEdit from './WalletEdit';

const resource = {
    list: WalletList,
    create: WalletCreate,
    edit: WalletEdit,
    icon: WalletIcon,
};

export default resource;
