import RateLastIcon from '@mui/icons-material/People';

import RateLastList from './RateLastList';
import RateLastCreate from './RateLastCreate';
import RateLastEdit from './RateLastEdit';

const resource = {
    list: RateLastList,
    create: RateLastCreate,
    edit: RateLastEdit,
    icon: RateLastIcon,
};

export default resource;
