import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './PaymentCreate';



// const PaymentTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;



const PaymentEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.billing.payment.fieldGroups.main" />

            <ReferenceInput source="accountId" reference="billing/accounts">
                <SelectInput optionText="id" isRequired fullWidth />
            </ReferenceInput>

            <ReferenceInput source="tariffPlanId" reference="tariff/plans">
                <SelectInput optionText="name" isRequired fullWidth />
            </ReferenceInput>

            <NumberInput source='price' label="resources.billing.payment.fields.price" />
            <TextInput source='comment' label="resources.billing.payment.fields.comment" />

            <Separator />
            <SectionTitle label="resources.billing.payment.fieldGroups.status" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="active" fullWidth />
                </Box>
            </Box>

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default PaymentEdit;
