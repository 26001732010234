import EntryIcon from '@mui/icons-material/People';

import ExecutionsList from './ExecutionsList';
import ExecutionsCreate from './ExecutionsCreate';
import ExecutionsEdit from './ExecutionsEdit';

const resource = {
    list: ExecutionsList,
    create: ExecutionsCreate,
    edit: ExecutionsEdit,
    icon: EntryIcon,
};

export default resource;
