import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './AccountCreate';



// const AccountTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const tariffPlanField = (record: any) => `#id${record.id} #${record.tariff_category.name} #${record.name}`;



const AccountEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.main" />

            <ReferenceInput source="profileUserId" reference="profile/users">
                <SelectInput optionText="username" isRequired fullWidth />
            </ReferenceInput>

            <ReferenceInput source="tariffPlanId" reference="tariff/plans">
                <SelectInput optionText={tariffPlanField} isRequired fullWidth />
            </ReferenceInput>

            <TextInput source="realCostUsdt" fullWidth />
            <TextInput source="startBalance" fullWidth />
            <TextInput source="leverage" fullWidth />

            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.status" />

            <BooleanInput source="active" fullWidth />

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default AccountEdit;
