import EntriesIcon from "@mui/icons-material/People";

import EntriesList from "./EntriesList";
import EntriesCreate from "./EntriesCreate";
import EntriesEdit from "./EntriesEdit";

const resource = {
  list: EntriesList,
  create: EntriesCreate,
  edit: EntriesEdit,
  icon: EntriesIcon,
};

export default resource;
