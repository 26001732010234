import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


// const stageField = (record: any ) => `#${record.tariffPlanId} #${record.name}`;


const CoinList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >
            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />

                <TextField source='name' label="resources.marketdata.coin.fields.name" />

                <TextField source='type' label="resources.marketdata.coin.fields.type" />

                <TextField source='symbol' label="resources.marketdata.coin.fields.symbol" />


                <BooleanField source="active" label="resources.marketdata.coin.fields.active" />

                <DateField source="createdAt" showTime label="resources.marketdata.coin.fields.createdAt" />

                <DateField source="updatedAt" showTime label="resources.marketdata.coin.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default CoinList;
