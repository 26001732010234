import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    return errors;
};


// const stageField = (record: any ) => `#${record.tariffPlanId} #${record.name}`;


const CoinCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                isPositive: true,
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.marketdata.coin.fieldGroups.main" />

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="name" isRequired fullWidth />
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="slug" isRequired fullWidth />
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="symbol" isRequired fullWidth />
            </Box>
        

            <Separator />
            <SectionTitle label="resources.marketdata.coin.fieldGroups.status" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="active" fullWidth />
                </Box>
            </Box>

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default CoinCreate;
