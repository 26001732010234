import AccountIcon from '@mui/icons-material/People';

import AccountList from './AccountList';
import AccountCreate from './AccountCreate';
import AccountEdit from './AccountEdit';

const resource = {
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit,
    icon: AccountIcon,
};

export default resource;
