import RoleIcon from '@mui/icons-material/People';

import RoleList from './RoleList';
import RoleCreate from './RoleCreate';
import RoleEdit from './RoleEdit';

const resource = {
    list: RoleList,
    create: RoleCreate,
    edit: RoleEdit,
    icon: RoleIcon,
};

export default resource;
