import EntryIcon from '@mui/icons-material/People';

import EntryList from './EntryList';
import EntryCreate from './EntryCreate';
import EntryEdit from './EntryEdit';

const resource = {
    list: EntryList,
    create: EntryCreate,
    edit: EntryEdit,
    icon: EntryIcon,
};

export default resource;
