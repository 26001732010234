// in src/comments.js
import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import {
    DateField,
    EditButton,
    useTranslate,
    NumberField,
    RecordContextProvider,
    useListContext,
} from 'react-admin';

import AvatarField from './AvatarField';
import { IProfileUser } from './types';

const MobileGrid = () => {
    const translate = useTranslate();
    const { data, isLoading } = useListContext<IProfileUser>();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{ margin: '0.5rem 0' }}>
                        <CardHeader
                            title={`
                                ${record.id},
                                ${record.username},
                                ${record.firstName},
                                ${record.lastName}
                            `}
                            subheader={
                                <>
                                    {translate(
                                        'resources.profile.user.fields.lastSeen'
                                    )}
                                    &nbsp;
                                    <DateField source="lastSeen" />
                                </>
                            }
                            avatar={<AvatarField size="45" />}
                            action={<EditButton />}
                        />
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default MobileGrid;
