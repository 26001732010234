import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    return errors;
};


const tariffPlanField = (record: any) => `#id${record.id} #${record.tariff_category.name} #${record.name}`;



const StageCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >

            <SectionTitle label="resources.tariff.stages.fieldGroups.main" />

            <TextInput source="name" isRequired fullWidth />
            <ReferenceInput source="tariffPlanId" reference="tariff/plans">
                <SelectInput optionText={tariffPlanField} isRequired />
            </ReferenceInput>

            <NumberInput source="minProfitPerStageProcent" isRequired fullWidth />
            <NumberInput source="maxLossPerStageProcent" isRequired fullWidth />
            <NumberInput source="tradingDaysCount" isRequired fullWidth />
            <NumberInput source="minProfitPerDayProcent" fullWidth />
            <NumberInput source="maxLossPerDayProcent" fullWidth />

            <Separator />
            <SectionTitle label="resources.tariff.stages.fieldGroups.status" />

            <BooleanInput source="active" fullWidth />

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default StageCreate;
