import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.price) {
        errors.price = 'ra.validation.required';
    }
    return errors;
};


const accounsField = (record: any) => `#${record.tariffPlanId} #${record.name}`;


const WalletCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                isPositive: true,
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.billing.wallet.fieldGroups.main" />

            <ReferenceInput source="accountId" reference="billing/accounts">
                <SelectInput optionText="id" isRequired fullWidth />
            </ReferenceInput>

            <ReferenceInput source="tariffPlanId" reference="tariff/plans">
                <SelectInput optionText="name" isRequired fullWidth />
            </ReferenceInput>

            <NumberInput source='price' label="resources.billing.wallet.fields.price" />
            <TextInput source='comment' label="resources.billing.wallet.fields.comment" />

            <Separator />
            <SectionTitle label="resources.billing.wallet.fieldGroups.status" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="active" fullWidth />
                </Box>
            </Box>

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default WalletCreate;
