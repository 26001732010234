import * as React from 'react';

import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    List,
    NullableBooleanInput,
    NumberField,
    SearchInput,
    Link,
    useRecordContext, FieldProps,
    TextField,
    DeleteButton,
    EditButton,
    ReferenceField
} from 'react-admin';

import { useMediaQuery, Theme, SxProps, Typography } from '@mui/material';


import MobileGrid from './MobileGrid';
import UserListAside from './UserListAside';

import AvatarField from './AvatarField';
import { IProfileUser } from './types';

interface Props extends FieldProps<IProfileUser> {
    size?: string;
    sx?: SxProps;
}



const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="lastSeen_gte" />,
    <NullableBooleanInput source="has_ordered" />,
    <NullableBooleanInput source="has_newsletter" defaultValue />,
];

const UserList = (props: Props) => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { size } = props;
    const record = useRecordContext<IProfileUser>();


    return (
        <List
            filters={isSmall ? userFilters : undefined}
            sort={{ field: 'lastSeen', order: 'DESC' }}
            perPage={25}
        // aside={<UserListAside />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField source='id' label="resources.profile.users.fields.id" />

                    <AvatarField record={record} size={size}
                        sx={{
                            mr: 1,
                            mt: -0.5,
                            mb: -0.5,
                        }}

                        label="resources.profile.users.fields.avatar"
                    />

                    <Link to="" label="resources.profile.users.fields.username">
                        <TextField source='username' />
                    </Link>

                    <TextField source='email' label="resources.profile.users.fields.email" />
                    <TextField source='firstName' label="resources.profile.users.fields.firstName" />
                    <TextField source='lastName' label="resources.profile.users.fields.lastName" />

                    <DateField source="lastSeen" label="resources.profile.users.fields.lastSeen" />

                    <ReferenceField source="profileRoleId" reference="profile/roles" link={false} label="resources.profile.users.fields.role">
                        <TextField source="name" />
                    </ReferenceField>

                    <ReferenceField source="profileRoleId" reference="profile/roles" link={false} label="resources.profile.users.fields.administrator">
                        <BooleanField source="administrator" />
                    </ReferenceField>

                    <ReferenceField source="profileRoleId" reference="profile/roles" link={false} label="resources.profile.users.fields.moderator">
                        <BooleanField source="moderator" />
                    </ReferenceField>

                    <BooleanField source='active' label="resources.profile.users.fields.active" />
                    <DateField source='createdAt' label="resources.profile.users.fields.createdAt" />
                    <DateField source='updatedAt' label="resources.profile.users.fields.updatedAt" />

                    <EditButton />
                    <DeleteButton />

                </Datagrid>
            )}
        </List>
    );
};

export default UserList;
