import EntryIcon from '@mui/icons-material/People';

import PositionsList from './PositionsList';
import PositionsCreate from './PositionsCreate';
import PositionsEdit from './PositionsEdit';

const resource = {
    list: PositionsList,
    create: PositionsCreate,
    edit: PositionsEdit,
    icon: EntryIcon,
};

export default resource;
