import React from "react";
import crudProvider from "ra-data-nestjsx-crud";

import {
  fetchUtils,
  Admin,
  Resource,
  Menu,
  ListGuesser,
  EditGuesser,
  ShowGuesser,
} from "react-admin";

// export const backend_url = "http://localhost:4040"
export const backend_url = "https://backend.trytrading.io";

export const api_url = backend_url + "/api";

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const nestProvider = crudProvider(api_url, httpClient);
