import PostsIcon from '@mui/icons-material/People';

import PostsList from './PostList';
import PostsCreate from './PostCreate';
import PostsEdit from './PostEdit';

const resource = {
    list: PostsList,
    create: PostsCreate,
    edit: PostsEdit,
    icon: PostsIcon,
};

export default resource;
