import CoinIcon from '@mui/icons-material/People';

import CoinList from './CoinList';
import CoinCreate from './CoinCreate';
import CoinEdit from './CoinEdit';

const resource = {
    list: CoinList,
    create: CoinCreate,
    edit: CoinEdit,
    icon: CoinIcon,
};

export default resource;
